<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicles">Fahrzeuge</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">{{ vehicle.license_plate }}</router-link></li>
              <li>Reparaturen</li>
            </ul>
            <div class="header-actions">
              <p></p>
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/reservations'">Reservierungen</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/damages'">Schäden</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/repairs'">Reparaturen</router-link></li>
            <li class="active"><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/insurance'">Versicherung</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/documents'">Dokumente</router-link></li>
          </ul>

          <div class="admin-body">

            <form @submit="update_vehicle" class="form" method="post">

              <div class="form-wrap">
                <label for="insurance_details">Beschreibung</label>
                <textarea v-model="vehicle.insurance_details" rows="5" class="form-input" type="text" name="insurance_details" id="insurance_details"></textarea>
              </div>

              <p>
                <input type="submit" value="Speichern ›" class="button button-orange button-small">
              </p>

            </form>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'

export default {
  name: 'admin_vehicle_repairs',
  components: {
    AdminNav
  },
  data () {
    return {
      errors: [],
      vehicle: {},
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle = response.data.vehicle;
      })
      .catch(error => {
        console.log(error);
      })

    },
    update_vehicle: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id, {
        'insurance_details': this.vehicle.insurance_details
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.',
        });
        this.vehicle = response.data.vehicle;
      })
      .catch(error => {
      error.response.data.errors.forEach(val => {
        this.$notify({
          group: 'alert',
          type: 'error',
          title: val,
        });
      })
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
